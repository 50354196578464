import React from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import mic from '../../assets/images/mic-icon.svg'

const DictaphoneAIDA = ({lang,changeStateResult}) => {
  const {
    transcript,
    finalTranscript,
    interimTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  //const [lang,setLang] = React.useState("en-US")
  function toggleVoice(){
    if(listening){
        SpeechRecognition.stopListening()
    }
    else{
        SpeechRecognition.startListening({ language: lang })
    }
  }
  React.useEffect(() => {
    if (finalTranscript !== '') {
     console.log('Got final result:', finalTranscript);
     changeStateResult(transcript)
    }
    }, [interimTranscript, finalTranscript]);
  React.useEffect(()=>{
    //changeStateResult(transcript)
  },[transcript])

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div>
      {/*<p>Microphone: {listening ? 'on' : 'off'}</p>*/}
      <div style={{display:'flex',justifyContent:'center',gap:'15px'}}>
        {/*<p>{transcript}</p>*/}
        <button style={{border:'none',backgroundColor:'transparent',cursor:'pointer',borderRight:listening? '3px solid red':'3px solid green'}} onClick={toggleVoice}>
            <img src={mic} alt='mic' />
        </button>
      </div>
    </div>
  );
};
export default DictaphoneAIDA;